<template>
    <div class="users-table">
        <div v-if="false" class="db-modal add-users">
            <div class="content">
                <div class="wrapper">
                    <h3>Add users</h3>
                    <div class="select-user">
                        <InputUnit title="Select Users" placeholder="Search name, username or ID" type="searchSelect" :selectOptions="userList" v-model="selectedUser" :onChange="id => console.log(id)"/>
                    </div>
                    <div class="user-list">
                        <div :key="ind" v-for="(ele, ind) in selectedUserList" class="user">
                            <div>
                                <h4>{{ ele.label }}</h4>
                                <p>@{{ ele.code }}</p>
                            </div>
                            <p>Trader</p>
                            <img src="@/assets/images/dashboard-pro/delete.svg" @click="remove(ele.code)"/>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="blue" @click="closeAddUsersModal()">Cancel</button>
                    <button class="light" @click="submitAuthedUsers()">Save</button>
                </div>
            </div>
        </div>
        <div class="db-modal add-to-portfolio">
            <div class="content">
                <div class="wrapper">
                    <h3>Add Users</h3>
                    <div class="form">
                        <InputUnit class="full" title="User" type="select" :selectOptions="userList" v-model="addUserForm.user_name"/>
                        <InputUnit title="Share" type="number" v-model="addUserForm.share" :hasBtn="false" />
                        <InputUnit title="Equity" type="number" v-model="addUserForm.equity" :hasBtn="false" />
                        <InputUnit class="full" title="Description (Optional)" type="textarea" v-model="addUserForm.note" />
                    </div>
                </div>
                <div class="btn-container">
                    <button class="blue" @click="closeAddUsersModal()">Cancel</button>
                    <button class="light" @click="addUserToPortfolio()">Add</button>
                </div>
            </div>
        </div>
        <div class="db-modal edit-users">
            <div class="content">
                <div class="wrapper">
                    <h3>{{ editUserMode == 'new' ? 'Add New User' : 'Edit User' }}</h3>
                    <div class="form">
                        <InputUnit title="Username" type="text" placeholder="Username..." :hasBtn="false" :disabled="editUserMode != 'new'" v-model="editing.user_name"/>
                        <InputUnit v-if="editUserMode == 'new'" title="Password" type="password" placeholder="Password..." :hasBtn="false" :disabled="false" v-model="editing.password"/>
                        <InputUnit v-if="editUserMode == 'new'" title="Confirm Password" type="password" placeholder="Confirm Password..." :hasBtn="false" :disabled="false" v-model="editing.confirm_password"/>
                        <InputUnit v-if="editUserMode == 'new' || editing.type == 0" title="First Name" type="text" placeholder="First Name..." :hasBtn="false" :disabled="false" v-model="editing.fname"/>
                        <InputUnit v-if="editUserMode == 'new' || editing.type == 0" title="Last Name" type="text" placeholder="Last Name..." :hasBtn="false" :disabled="false" v-model="editing.lname"/>
                        <InputUnit v-if="editUserMode == 'new' || editing.type == 0" title="Phone" type="text" placeholder="Phone..." :hasBtn="false" :disabled="false" v-model="editing.contact"/>
                        <InputUnit v-if="editUserMode == 'new' || editing.type == 0" title="Email" type="text" placeholder="Email..." :hasBtn="false" :disabled="false" v-model="editing.email"/>
                        <InputUnit v-if="editUserMode == 'new' || editing.type != 0" title="Role" type="select" :selectOptions="roleOptions" v-model="editing.role" :hasBtn="false" :disabled="false"/>
                        <div v-if="editUserMode != 'new'" class="edit-pwd-container"><a href="#" @click="openChangePasswordModal()">Edit Password...</a></div>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="blue" @click="closeUsersModal(0)">Cancel</button>
                    <button v-if="editUserMode == 'new'" class="light" @click="closeUsersModal(1)">Create</button>
                    <button v-if="editUserMode == 'edit'" class="light" @click="closeUsersModal(1)">Save</button>
                </div>
            </div>
        </div>
        <div class="db-modal edit-password">
            <div class="content">
                <div class="wrapper">
                    <h3>Edit Password</h3>
                    <div class="form">
                        <InputUnit title="Username" type="text" placeholder="Username..." :hasBtn="false" :disabled="editUserMode != 'new'" v-model="editPassword.name"/>
                        <InputUnit title="Old Password" type="password" placeholder="Old Password..." :hasBtn="false" :disabled="false" v-model="editPassword.pwd"/>
                        <InputUnit title="New Password" type="password" placeholder="New Password..." :hasBtn="false" :disabled="false" v-model="editPassword.npwd"/>
                        <InputUnit title="Confirm New Password" type="password" placeholder="Confirm New Password..." :hasBtn="false" :disabled="false" v-model="editPassword.npwd_confirm"/>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="blue" @click="closeChangePasswordModal(0)">Cancel</button>
                    <button class="light" @click="changePassword()">Submit</button>
                </div>
            </div>
        </div>
        <div v-if="type == 'default'" class="filters-container">
            <div class="pair">
                <h5>User</h5>
                <span :class="usertype == 'all' ? 'selected': ''" @click="usertype = 'all'">All</span>
                <span v-if="type == 'default'" :class="usertype == 'admin' ? 'selected': ''" @click="usertype = 'admin'">Admin</span>
                <span :class="usertype == 'investor' ? 'selected': ''" @click="usertype = 'investor'">Investors</span>
                <span :class="usertype == 'sales' ? 'selected': ''" @click="usertype = 'sales'">Sales</span>
                <span :class="usertype == 'user' ? 'selected': ''" @click="usertype = 'user'">Users</span>
            </div>
        </div>
        <div class="searchAndAdd">
            <InputUnit title="" v-model="keywords" placeholder="Search by username" type="text" />
            <button v-if="type == 'in-portfolio'" class="blue" @click="openAddUsersModal()">+ Add</button>
            <button v-if="type == 'default'" class="blue" @click="openUsersModal()">+ Add</button>
        </div>
        <table v-if="type == 'default'" class="no-action">
            <tr class="header">
                <th>User Name</th>
                <th>ID</th>
                <th>Contact</th>
                <th>Role</th>
                <th>Action</th>
            </tr>
            <tr :key="ind" v-for="(ele, ind) in list" @click="openUsersModal(1, ele.user_name)">
                <td>{{ ele.user_name }}</td>
                <td>{{ ele.user_id }}</td>
                <td>{{ ele.contact }}</td>
                <td>{{ ele.role }}</td>
                <td><a href="#"><img src="@/assets/images/dashboard-pro/edit.svg"/></a><a href="#" @click.stop="deleteUser(ele.user_id, ele.user_name)"><img src="@/assets/images/dashboard-pro/delete.svg"/></a></td>
            </tr>
        </table>
        <table v-if="type == 'in-portfolio'">
            <tr class="header">
                <th>User Name</th>
                <th>Equity</th>
                <th>Share</th>
                <th>Action</th>
            </tr>
            <tr :key="ind" v-for="(ele, ind) in list" >
                <td>{{ ele.user_name }}</td>
                <td>{{ ele.equity }}</td>
                <td>{{ ele.share }}</td>
                <td><a href="#"><img src="@/assets/images/dashboard-pro/delete.svg" @click="deleteUserShare(ele.user_name)"/></a></td>
            </tr>
        </table>
        <div v-if="type == 'default'" class="mobile-user-list">
            <div :key="ind" v-for="(ele, ind) in list" @click="openUsersModal(1, ele.user_name)">
                <div class="content">
                    <h6>{{ ele.user_id }}</h6>
                    <h5>{{ ele.user_name }}</h5>
                    <p>{{ ele.role }}</p>
                    <p>{{ ele.contact }}</p>
                </div>
                <div class="action">
                    <a href="#"><img src="@/assets/images/dashboard-pro/edit.svg"/></a>
                    <a href="#" @click.stop="deleteUser(ele.user_id, ele.user_name)"><img src="@/assets/images/dashboard-pro/delete.svg"/></a>
                </div>
            </div>
        </div>
        <div v-if="type == 'in-portfolio'" class="mobile-user-list">
            <div :key="ind" v-for="(ele, ind) in list">
                <div class="content">
                    <h5>{{ ele.user_name }}</h5>
                    <p>equity: {{ ele.equity }}</p>
                    <p>share: {{ ele.share }}</p>
                </div>
                <div class="action">
                    <a href="#"><img src="@/assets/images/dashboard-pro/delete.svg" @click="deleteUserShare(ele.user_name)"/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputUnit from '@/components/InputUnit';
import Cookies from 'js-cookie'
import $ from 'jquery';
import {apiUserRegis, apiUserInfo, apiUserRole, apiRenewPassword, apiUserList, apiSetUserShare, apiDeleteUserShare, apiDeleteUser} from '@/apis/pro/user.js';
import {apiAddPortfolio} from '@/apis/pro/portfolios.js';
import {MD5} from '@/utils/Encryption.js';
export default {
    name: 'UsersTable',
    data(){
        return {
            newUserForm:{
                portfolio: 'portfolioa',
                exchange: 'binance',
                accountName: '',
                accountAPIKey: '',
                accountAPISecret: '',
                accountAPIPassphrase: ''
            },
            userList: [],
            usertype: 'all',
            selectedUser: '',
            selectedUserList: [],
            editUserMode: 'new',
            user:{
                role: 'admin'
            },
            roleOptions:[{
                title: 'Admin',
                value: 'admin'
            }, {
                title: 'Investor',
                value: 'investor'
            }, {
                title: 'Sales',
                value: 'sales'
            }, {
                title: 'User',
                value: 'user'
            }],
            keywords: '',
            editing: {
                type: 0, //0:self, 1:others
                user_name: '',
                fname: '',
                lname: '',
                password: '',
                confirm_password: '',
                role: '',
                contact: '',
                email: '',
            },
            editPassword: {
                name: '',
                pwd: '',
                npwd: '',
                npwd_confirm: ''
            },
            addUserForm: {
                user_name: '',
                share: '0',
                equity: '0',
                note: ''
            }
        }
    },
    methods: {
        openAddUsersModal(){
            $('.db-modal.add-to-portfolio').fadeIn(300).css('display', 'flex');
        },
        closeAddUsersModal(){ //Cancel = 0, Save = 1
            $('.db-modal.add-to-portfolio').fadeOut(300);
        },
        openUsersModal(type=0, uid=''){ 
            this.editUserMode = type ? 'edit' : 'new'
            this.resetEditing()
            var self = Cookies.get('dcbotLogin_uname')
            if(type == 1){
                var t = this.data.find(ele => ele.user_name == uid)
                if(t !== undefined){
                    this.editing = {
                        type: self == t.user_name ? 0 : 1,
                        user_name: t.user_name,
                        fname: self == t.user_name ? t.fname : '',
                        lname: self == t.user_name ? t.lname : '',
                        password: '',
                        role: t.role,
                        contact: t.contact,
                        email: t.email,
                    }
                }
            }
            $('.db-modal.edit-users').fadeIn(300).css('display', 'flex');
        },
        openChangePasswordModal(){
            $('.db-modal.edit-users').fadeOut(300)
            this.editPassword.name = this.editing.user_name
            $('.db-modal.edit-password').fadeIn(300).css('display', 'flex')
        },
        closeChangePasswordModal(){
            $('.db-modal.edit-password').fadeOut(300);
        },
        closeUsersModal(mode=1){ //Cancel = 0, Save = 1
            if(mode){
                // save
                if(this.editUserMode == 'new'){
                    if(this.userValidation()){
                        this.handleCreateUser()
                        $('.db-modal.edit-users').fadeOut(300);
                    }
                }else{
                    this.handleEditUser()
                }
            }else{
                $('.db-modal.edit-users').fadeOut(300);
            }
        },
        changePassword(){
            if(this.passwordValidation()){
                apiRenewPassword({
                    name: this.editPassword.name,
                    pwd: MD5(this.editPassword.pwd),
                    npwd: MD5(this.editPassword.npwd),
                    npwd_confirm: MD5(this.editPassword.npwd_confirm)
                }).then(res => {
                    if(res.data.result){
                        this.$toast.success('Password Changed!', {
                            timeout: 2000
                        });
                        location.reload()
                    }else{
                        this.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        },
        remove(user){
            this.userList.push(this.selectedUserList.find(ele => ele.code == user))
            this.selectedUserList = this.selectedUserList.filter(ele => ele.code != user)
        },
        resetEditing(){
            this.editing = {
                type: 0, //0:self, 1:others
                user_name: '',
                fname: '',
                lname: '',
                password: '',
                role: 'admin',
                contact: '',
                email: '',
            }
        },
        userValidation(){
            if(this.editUserMode == 'new'){
                if(this.editing.user_name == ''){
                    this.$toast.error(`請輸入使用者名稱`, {
                        timeout: 2000
                    });
                    return false;
                }else if(this.editing.password == ''){
                    this.$toast.error(`請輸入密碼`, {
                        timeout: 2000
                    });
                    return false;
                }else if(this.editing.password != this.editing.confirm_password){
                    this.$toast.error(`兩次輸入密碼須一致`, {
                        timeout: 2000
                    });
                    return false;
                }
                return true;
            }
            return true;
        },
        passwordValidation(){
            if(this.editPassword.name == ''){
                this.$toast.error(`請輸入使用者名稱`, {
                    timeout: 2000
                });
                return false;
            }else if(this.editPassword.npwd == ''){
                this.$toast.error(`請輸入密碼`, {
                    timeout: 2000
                });
                return false;
            }else if(this.editPassword.npwd != this.editPassword.npwd_confirm){
                this.$toast.error(`兩次輸入密碼須一致`, {
                    timeout: 2000
                });
                return false;
            }
            return true;
        },
        handleCreateUser(){
            let tmp = Object.assign({}, this.editing)
            tmp.password = MD5(this.editing.password)
            delete tmp.confirm_password
            apiUserRegis(tmp).then(res => {
                if(res.data.result){
                    this.$toast.success('User Created!', {
                        timeout: 2000
                    });
                    location.reload()
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        handleEditUser(){
            if(this.editing.type == 0){
                apiUserInfo({
                    contact: this.editing.contact,
                    email: this.editing.email,
                    fname: this.editing.fname,
                    lname: this.editing.lname
                }).then(res => {
                    if(res.data.result){
                        this.$toast.success('User Updated!', {
                            timeout: 2000
                        });
                        location.reload()
                    }else{
                        this.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }else{
                apiUserRole({
                    user_name: this.editing.user_name,
                    role: this.editing.role
                }).then(rtn => {
                    if(rtn.data.result){
                        this.$toast.success('User Updated!', {
                            timeout: 2000
                        });
                       location.reload()
                    }else{
                        this.$toast.error(rtn.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        },
        loadUsers(){
            apiUserList().then(res => {
                if(res.data.result){
                    this.userList = res.data.data.map(obj => ({title: obj.user_name, value: obj.user_name})).filter(obj => !this.data.some(ele => ele.user_name == obj.title))
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        submitAuthedUsers(){
            apiAddPortfolio({
                product_name: this.portfolio,
                assigned: this.selectedUserList.reduce((rtn, now) => rtn += `${now.label},`, '').slice(0, -1)
            }).then(res => {
                if(res.data.result){
                    this.$toast.success('Updated!', {
                        timeout: 2000
                    });
                   //location.reload()
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        addUserToPortfolioValidation(){
            if(this.addUserForm.user_name == ''){
                this.$toast.error('Please select a user', {
                    timeout: 2000
                });
                return false
            }else if(this.addUserForm.share == ''){
                this.$toast.error(`Share can't be empty`, {
                    timeout: 2000
                });
                return false
            }else if(this.addUserForm.equity == ''){
                this.$toast.error(`Equity can't be empty`, {
                    timeout: 2000
                });
                return false
            }
            return true
        },
        addUserToPortfolio(){
            if(this.addUserToPortfolioValidation()){
                let tmp = Object.assign({
                    portfolio: this.portfolio
                }, this.addUserForm)
                apiSetUserShare(tmp).then(res => {
                    if(res.data.result){
                        this.$toast.success('Success!', {
                            timeout: 2000
                        });
                        location.reload()
                    }else{
                        this.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        }
        ,deleteUserShare(uid){
            this.$confirm('Delete User From Portfolio', `Are you sure you want to delete user '${uid}' from this portfolio?`).then(res => {
                if(res){
                    apiDeleteUserShare({
                        portfolio: this.$route.params.id,
                        user_name: uid
                    }).then(res => {
                        if(res.data.result){
                            this.$toast.success('Portfolio Updated!', {
                                timeout: 2000
                            });
                            location.reload()
                        }else{
                            this.$toast.error(res.data.err_msg, {
                                timeout: 2000
                            });
                        }
                    })
                }
            })
        },
        deleteUser(uid, name){
            this.$confirm('Delete user', `Are you sure you want to delete user '${name}'?`).then(res => {
                if(res){
                    apiDeleteUser({
                    user_id: uid
                    }).then(res => {
                        if(res.data.result){
                            this.$toast.success('User Deleted!', {
                                timeout: 2000
                            });
                            location.reload()
                        }else{
                            this.$toast.error(res.data.err_msg, {
                                timeout: 2000
                            });
                        }
                    })
                }
            })
        },
    },
    components: {
        InputUnit,
    },
    props: {
        type: {
            type: String,
            default: 'default'
        },
        data: {
            type: Array,
            default: () => []
        },
        portfolio: {
            type: String,
            default: ''
        }
    },
    mounted() {
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
        if(this.type == 'in-portfolio'){
            this.loadUsers()
        }
    },
    computed: {
        list(){
            return this.data.filter(ele => ele.user_name.toLowerCase().indexOf(this.keywords.toLowerCase())>=0 && (ele.role == this.usertype || this.usertype == 'all'));
        }
    },
}
</script>