<template>
    <div class="db-container dashboard-pro-users">
        <div class="db-content">
            <h2 class="title">
                User Management
            </h2>
            <UsersTable :data="users"/>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import $ from 'jquery';
import UsersTable from '@/components/UsersTable';
import Cookies from 'js-cookie'
import {apiUserList, apiGetUserInfo} from '@/apis/pro/user.js';
/*
import Switcher from '@/components/Switcher';
import InputUnit from '@/components/InputUnit';
import NetValue from '@/components/NetValue';
import AddPortfolio from '@/components/AddPortfolio';
*/
// import {apiNewsDetail} from '@/apis/news.js';

export default {
    name: 'Users',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            tabOptions: [{
                title: '<h2>45</h2><p>In operation</p>',
                value: 'inOperation'
            }, {
                title: '<h2>2</h2><p>Settled</p>',
                value: 'settled'
            }, {
                title: '<h2>141</h2><p>Total portfolios</p>',
                value: 'totalPortfolios'
            }],
            tab: 'inOperation',
            searchPortfolio: '',
            chartData: [
                {label: new Date('2022-05-12'), data: -5000},
                {label: new Date('2022-05-13'), data: 14000},
                {label: new Date('2022-05-14'), data: 8000},
                {label: new Date('2022-05-15'), data: 25000},
                {label: new Date('2022-05-16'), data: 19000},
                {label: new Date('2022-05-17'), data: 40000},
                {label: new Date('2022-05-18'), data: 60000},
            ],
            users: [],
            loading: true
        }
    },
    components: {
        UsersTable,
    },
    methods: {
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
        loadUserList(){
            var self = Cookies.get('dcbotLogin_uname')
            apiUserList().then(res => {
                if(res.data.result){
                    apiGetUserInfo().then(rtn => {
                        if(rtn.data.result){
                            this.users = res.data.data
                            let objIndex = this.users.findIndex((obj => obj.user_name == self));
                            this.users[objIndex] = Object.assign({}, this.users[objIndex], rtn.data.data)
                            console.log(this.users)
                            this.loading = false
                        }else{
                            this.$toast.error(rtn.data.err_msg, {
                                timeout: 2000
                            });
                        }
                    })
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        }
    },
    mounted() {
        this.loadUserList()
    },
    computed: {
        myStyles() {
          return {
            height: '100%',
            width: '100%',
            position: 'relative',
          };
        }
    },
}
</script>
